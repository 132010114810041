import React from "react";

const Footer = () => {
  return (
    <footer className="bg-gray-200 py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between items-center">
          <div className="flex justify-between items-center w-full">
            <div className="w-full md:w-auto text-blue-500">
              <img src="/Images/SB_Logo.png" className="h-24 w-24" alt="" />
            </div>
            <div className="w-full md:w-auto text-blue-400 text-sm md:mt-0">
              <p>Have any question</p>
              <a
                        href="tel:+91 22 69800800"
                        target="_blank"
                        rel="noopener noreferrer"
                      >+91 2269800800</a>
            </div>
          </div>
          <div className="w-full md:w-auto mt-4 md:mt-0">
            <ul className="flex justify-center md:justify-end">
              <li className="mx-2">
                <a href="#" className="text-white hover:text-gray-400">
                  <i className="fab fa-facebook fa-lg"></i>
                </a>
              </li>
              <li className="mx-2">
                <a href="#" className="text-white hover:text-gray-400">
                  <i className="fab fa-instagram fa-lg"></i>
                </a>
              </li>
              <li className="mx-2">
                <a href="#" className="text-white hover:text-gray-400">
                  <i className="fab fa-linkedin fa-lg"></i>
                </a>
              </li>
              <li className="mx-2">
                <a href="#" className="text-white hover:text-gray-400">
                  <i className="fab fa-twitter fa-lg"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-8">

        </div>
        <div className="mt-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="w-full md:w-auto text-center md:text-left">
              <p className="text-gray-800 text-sm">
                Sea Breeze Ship Management Private Limited <br />
                Unit 2C, 2nd Floor, Rushabh Chambers Premises Co-op Society Ltd,
                Makwana Road, Marol <br />
                Andheri East, Mumbai 400059
              </p>
              <a
                        href="tel:+91 22 6980080"
                        target="_blank"
                        rel="noopener noreferrer"
                      > +912269800800</a>
            </div>
            <div className="w-full md:w-auto mt-4 md:mt-0 text-center md:text-right">
              <p className="text-gray-800 text-sm">
                © 2024 Seabreeze Group. All Rights Reserved
              </p>
              <a href="/privacy" className="text-gray-800 hover:text-white">
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
