import React from "react";

const Vision = () => {
  return (
    <>
      <div className="w-full md:w-1/2 px-4">
        <div className="bg-blue-400 p-8 rounded-lg shadow-lg h-full">
          <h2 className="text-2xl font-bold mb-4 text-white">Our Vision</h2>
          <p className="text-white leading-relaxed">
            We seek to work towards forming an organization which, as it grows,
            also promotes inclusive development and conservation of the
            environment
          </p>
        </div>
      </div>
    </>
  );
};

export default Vision;
